.sign-in {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .sign-in__password {
    font-family: Ubuntu, sans-serif;
    font-size: 16.6px;
    margin-bottom: 47px; }
  .sign-in__send {
    font-family: Montserrat, sans-serif;
    font-size: 16.6px;
    margin-bottom: 41px; }
  .sign-in__form-group {
    height: 65px !important;
    background: #f3f3f3;
    font-family: 'Ubuntu', sans-serif;
    overflow-x: hidden;
    position: relative; }
    .sign-in__form-group input,
    .sign-in__form-group select {
      background-color: transparent !important;
      padding: 0 15px !important;
      font-size: 16.6px;
      border: none;
      height: 20px;
      appearance: none;
      display: flex;
      align-items: flex-end; }
  .sign-in__feedback-message {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 15px; }
    .sign-in__feedback-message.invalid-feedback.-show {
      display: block; }
  .sign-in__label {
    font-size: 12.5px;
    font-weight: bold;
    padding-bottom: 0;
    opacity: 0.5;
    white-space: nowrap;
    padding-left: 15px;
    margin-bottom: 0;
    padding-top: 7px; }
    .sign-in__label-about {
      opacity: 0.5;
      font-family: Ubuntu, sans-serif;
      font-size: 12.5px;
      font-weight: bold;
      letter-spacing: 1.25px; }
    .sign-in__label.-no-opacity {
      opacity: 1;
      color: rgba(21, 21, 21, 0.5); }
  .sign-in__back {
    position: absolute;
    left: -160px;
    top: 20px;
    cursor: pointer; }
  .sign-in .modal-content {
    top: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: transparent;
    border: none;
    width: 620px !important;
    padding-right: 16px;
    padding-top: 17px;
    right: -16px; }
    @media (max-width: 768px) {
      .sign-in .modal-content {
        top: 30px;
        right: initial;
        left: initial;
        padding-right: 0; } }
    @media (max-width: 768px) {
      .sign-in .modal-content .modal-body {
        width: 100%;
        padding: 0; } }
  .sign-in__body {
    background: #fff;
    min-width: 577px !important;
    max-width: 577px !important;
    display: flex;
    flex-direction: column;
    padding-left: 49px;
    padding-right: 49px;
    padding-bottom: 15px; }
    .sign-in__body.-small {
      height: 509px; }
    @media (max-width: 480px) {
      .sign-in__body {
        min-width: initial !important;
        max-width: initial !important;
        width: auto;
        padding-left: 15px;
        padding-right: 15px; } }
  .sign-in__forget {
    text-align: right;
    font-family: Montserrat, sans-serif;
    margin-bottom: 41px;
    font-size: 16.6px;
    cursor: pointer; }
  .sign-in__btn-container {
    margin-bottom: 31px;
    display: flex;
    justify-content: center; }
  .sign-in__btn {
    height: 55px;
    width: 55%;
    font-family: Montserrat, sans-serif;
    font-size: 16.6px;
    font-weight: bold;
    align-self: center; }
    .sign-in__btn .spinner {
      margin-right: 13px; }
  .sign-in__yes, .sign-in__no {
    height: 55px;
    font-family: Montserrat, sans-serif;
    margin-bottom: 31px;
    font-size: 16.6px;
    font-weight: bold;
    width: 220px; }
  .sign-in__account {
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 16.6px; }
  .sign-in__sign {
    cursor: pointer;
    color: #000;
    font-size: 17px;
    padding: 0; }
    .sign-in__sign:hover, .sign-in__sign:active {
      color: #000;
      text-decoration: none; }
  .sign-in__tab {
    font-size: 16.6px;
    letter-spacing: 1.66px;
    cursor: pointer;
    opacity: 0.5;
    margin-bottom: 47px; }
    .sign-in__tab.-is-active {
      color: #151515;
      opacity: 1;
      position: relative;
      display: inline-block; }
      .sign-in__tab.-is-active::before, .sign-in__tab.-is-active::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        border-bottom: solid 1px #151515;
        padding-bottom: 12px;
        min-height: 100%;
        z-index: 10; }
      .sign-in__tab.-is-active::before {
        bottom: 0;
        width: 20px; }
      .sign-in__tab.-is-active::after {
        bottom: -5px;
        width: 40px; }
  .sign-in__close {
    width: 67px;
    height: 67px;
    background: #fff;
    position: absolute;
    display: flex;
    top: -17px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    right: -15px;
    cursor: pointer;
    z-index: 9999; }
    @media (max-width: 768px) {
      .sign-in__close {
        top: 0;
        right: 0; } }
  .sign-in__logo-container {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    justify-content: center; }
    .sign-in__logo-container img {
      max-width: 160px;
      height: auto;
      object-fit: contain; }
      @media (max-width: 480px) {
        .sign-in__logo-container img {
          max-width: 130px; } }
  .sign-in__zip {
    opacity: 0.9;
    font-family: Ubuntu, sans-serif;
    font-size: 24px;
    text-align: center;
    margin-top: 79px;
    margin-bottom: 79px; }
