.HeaderNav {
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  height: 77px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px; }
  @media (max-width: 768px) {
    .HeaderNav {
      padding: 0 15px;
      height: fit-content; } }
  @media (max-width: 768px) {
    .HeaderNav .toggler-button {
      margin: 16px 0; } }
  .HeaderNav__brand {
    margin: 0;
    text-decoration: none !important; }
    .HeaderNav__brand img {
      width: 60px;
      height: 60px; }
  @media (max-width: 768px) {
    .HeaderNav__authed {
      display: flex;
      flex-direction: row;
      margin-left: auto; } }
  .HeaderNav__authed-notifications.--top {
    display: none; }
    @media (max-width: 768px) {
      .HeaderNav__authed-notifications.--top {
        display: block; } }
  @media (max-width: 768px) {
    .HeaderNav__authed-notifications.--bottom {
      display: none; } }
  .HeaderNav__authed-profile.--top {
    display: none; }
  @media (max-width: 768px) {
    .HeaderNav__authed-profile.--bottom {
      width: 100%;
      position: relative;
      top: -15px; }
      .HeaderNav__authed-profile.--bottom a {
        display: flex;
        justify-content: center;
        position: relative;
        left: -15px;
        font-size: 19px;
        letter-spacing: 0.1em;
        color: #6e708b !important;
        text-decoration: none !important; } }
    @media (max-width: 768px) and (max-width: 1200px) {
      .HeaderNav__authed-profile.--bottom a {
        font-size: 16px; } }
  .HeaderNav__authed-profile-dropdown-menu {
    text-align: center; }
    @media (max-width: 768px) {
      .HeaderNav__authed-profile-dropdown-menu {
        width: 100% !important; } }
  .HeaderNav__authed-profile-email {
    display: none; }
    @media (max-width: 768px) {
      .HeaderNav__authed-profile-email {
        display: block; } }
  .HeaderNav__list {
    display: flex;
    list-style: none;
    justify-content: center;
    flex-wrap: wrap;
    padding: 25px 0;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0; }
    .HeaderNav__list-item {
      margin: 0 20px; }
      @media (max-width: 992px) {
        .HeaderNav__list-item {
          margin: 0 10px; } }
      @media (max-width: 768px) {
        .HeaderNav__list-item {
          width: 100%;
          text-align: center; } }
      .HeaderNav__list-item a {
        font-size: 19px;
        line-height: 23px;
        letter-spacing: 0.1em;
        color: #6e708b;
        text-decoration: none !important; }
        @media (max-width: 1200px) {
          .HeaderNav__list-item a {
            font-size: 16px; } }
      .HeaderNav__list-item a.-is-active {
        color: #151515;
        font-weight: 500;
        position: relative;
        display: inline-block; }
      .HeaderNav__list-item a.-is-active::after {
        content: '';
        display: block;
        position: absolute;
        left: 20%;
        right: 20%;
        bottom: -8px;
        border-bottom: solid 2px #fde000;
        padding-bottom: 12px;
        min-height: 100%;
        z-index: 10; }
      @media (max-width: 768px) {
        .HeaderNav__list-item {
          margin-bottom: 20px; } }
    @media (max-width: 768px) {
      .HeaderNav__list {
        padding-bottom: 0; } }
  .HeaderNav__buttons-container {
    display: flex;
    align-items: center; }
    @media (max-width: 768px) {
      .HeaderNav__buttons-container {
        justify-content: center;
        margin-bottom: 20px; } }
  .HeaderNav__buttons {
    width: 130px;
    height: 40px;
    border: 0;
    border-radius: 8px;
    font-size: 16px;
    line-height: 15px;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center; }
    .HeaderNav__buttons img {
      width: 16px;
      height: 20px;
      margin-right: 10px; }
    .HeaderNav__buttons.-login {
      color: #fff;
      background: #4b4d67;
      margin-right: 10px; }
    .HeaderNav__buttons.-signup {
      color: #4f4f4f;
      background: #fde000;
      margin-left: 10px; }
  .HeaderNav__avatar {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 768px) {
      .HeaderNav__avatar {
        margin-right: 10px; } }
    .HeaderNav__avatar img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .HeaderNav__dropdown-item {
    padding: 10px 28px;
    font-weight: bold; }
    .HeaderNav__dropdown-item a {
      color: #151515;
      display: block; }
      .HeaderNav__dropdown-item a:hover {
        text-decoration: none; }
    .HeaderNav__dropdown-item:active {
      background-color: inherit; }
    .HeaderNav__dropdown-item.-is-red {
      color: #f44336; }
  .HeaderNav .navbar-collapse {
    flex-grow: unset;
    transition: 0.3s linear; }
    @media (max-width: 768px) {
      .HeaderNav .navbar-collapse {
        position: fixed;
        top: 68px;
        width: 100%;
        left: 0;
        z-index: 1;
        box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.1);
        background-color: #fff; } }
  .HeaderNav .dropdown-menu {
    width: 252px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.25); }
