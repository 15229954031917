.Notifications {
  display: flex;
  align-items: center;
  justify-content: center; }
  .Notifications__icon {
    width: 30px;
    position: relative;
    margin-right: 20px;
    cursor: pointer; }
    .Notifications__icon-img {
      width: 24px;
      height: 24px; }
    .Notifications__icon-has-notification {
      position: absolute;
      top: 0;
      right: 4px;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: #dd2465; }
  .Notifications__list {
    margin-top: 15px;
    border-radius: 4px;
    padding: 0;
    width: 300px !important;
    overflow: auto; }
    @media (max-width: 768px) {
      .Notifications__list {
        position: fixed !important;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 30px) !important; } }
    .Notifications__list-empty {
      padding: 10px;
      font-size: 15px;
      font-weight: 400;
      text-align: center; }
    .Notifications__list-item {
      position: relative;
      padding: 15px 10px;
      border-bottom: 1px solid #ababab;
      color: #ababab;
      background-color: #fff; }
      .Notifications__list-item-body {
        display: flex;
        justify-content: space-between; }
        .Notifications__list-item-body-title {
          font-size: 12px;
          font-weight: 500; }
        .Notifications__list-item-body-time {
          color: #888;
          font-size: 10px; }
      .Notifications__list-item-footer {
        font-size: 12px;
        font-weight: bold;
        color: #151515; }
      .Notifications__list-item:nth-child(even) {
        background-color: #eee;
        border-bottom-color: transparent; }
